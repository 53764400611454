import { ProductLaunchSignupComponent } from './Layout/elements/product-launch/product-launch-signup/product-launch-signup.component';
import { MeetOurManagementComponent } from './Layout/Components/about-us/meet-our-management/meet-our-management.component';
// tslint:disable: import-spacing
import { LeaderBioComponent }       from './Layout/Components/about-us/leader-bio/leader-bio.component';
import { SingleProductComponent }   from './Layout/Components/products/catalog/single-product/single-product.component';
import { CatalogComponent }         from './Layout/Components/products/catalog/catalog.component';
import { CatalogGridComponent }     from './Layout/Components/products/catalog-grid/catalog-grid.component';
import { SingleItemComponent }      from './Layout/Components/products/catalog-grid/single-item/single-item.component';
import { NgModule }                 from '@angular/core';
import { Routes, RouterModule }     from '@angular/router';
import { ProductsComponent }        from './Layout/Components/products/products.component';
import { ContactUsComponent }       from './Layout/Components/contact-us/contact-us.component';
import { AboutUsComponent }         from './Layout/Components/about-us/about-us.component';
import { FrontPageComponent }       from './Layout/Components/front-page/front-page.component';
import { LayoutComponent }          from './Layout/layout.component';
import { PrivacyComponent } from './Layout/Components/privacy/privacy.component';

const routes: Routes = [
  {

    path: '', component: LayoutComponent, data: {title: 'Almaject | Home'}, children: [

      { path: '', component: FrontPageComponent },

      { path: 'product-launch-signup', component: ProductLaunchSignupComponent },

      { path: 'about-us', component: AboutUsComponent, data: {title: 'Almaject | Company Overview'} },

      { path: 'management', children: [

        { path: '', component: MeetOurManagementComponent, data: {title: 'Almaject | Management'}},

        { path: ':slug', component: LeaderBioComponent, data: {title: 'Almaject | :title'}}

      ]},

    { path: 'contact-us', component: ContactUsComponent, data: {title: 'Almaject | Contact Us'}},

    { path: 'products', children: [

      { path: '', component: ProductsComponent, data: {title: 'Almaject | Products'}},

      { path: ':id', component: SingleItemComponent, data: {title: 'Almaject | :id'}}

    ]},

    { path: 'privacy-policy', component: PrivacyComponent, data: {title: 'Almaject | Privacy Policy'}}

  ]},

  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
