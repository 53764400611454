// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit } from '@angular/core';
import { WpApiService }      from './../../../Services/wp-api.service';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.less']
})
export class PrivacyComponent implements OnInit {
  wpData    : any;
  wpDataErr : any;
  constructor(private wp: WpApiService) { }

  ngOnInit() {
    this.wp.getSpecificPage('privacy-policy').subscribe(data => {
      this.wpData = data;
    }, (err) => {
      this.wpDataErr = err;
    });
  }
}
