// tslint:disable: import-spacing
import { BrowserModule, BrowserTransferStateModule }        from '@angular/platform-browser';
import { LayoutModule }         from './Layout/layout.module';
import { NgModule }             from '@angular/core';
import { AppRoutingModule }     from './app-routing.module';
// Components
import { AppComponent }         from './app.component';
import { AppModule }            from './app.module';
import { ServiceWorkerModule }  from '@angular/service-worker';
import { environment }          from '../environments/environment';

@NgModule({
  imports: [
    AppRoutingModule,
    LayoutModule,
    AppModule,
    BrowserTransferStateModule,
    // ServiceWorkerModule.register('/wp-content/themes/almaject/dist/ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppBrowserModule { }
