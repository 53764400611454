import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-loader',
  template: `
  <div class="loader-container">
  <div class="loader"></div>
  </div>
  `,
  styleUrls: ['./content-loader.component.less']
})
export class ContentLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
