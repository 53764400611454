// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit,  Input, HostBinding } from '@angular/core';
import { trigger, state, style, animate, transition, query, stagger, keyframes} from '@angular/animations';
import { WpApiService }         from './../../../../Services/wp-api.service';
import { OrderPipe }            from 'ngx-order-pipe';

@Component({
  selector: 'app-catalog-grid',
  templateUrl: './catalog-grid.component.html',
  styleUrls: ['./catalog-grid.component.less'],
  animations: [
    trigger('aliasesAnimation', [
      transition('* => *', [
        query(':enter', style({opacity: 0}), {optional: true}),
        query(':enter', stagger('50ms', [
          animate('250ms ease-in', keyframes([
            style({opacity: 0, transform: 'translateX(-20%)', offset: 0}),
            style({opacity: 0.5, transform: 'translateX(5%)', offset: 0.3}),
            style({opacity: 1, transform: 'translateX(0)', offset: 1.0})
          ]))
        ]), {optional: true}),
        query(':leave', stagger('50ms', [
          animate('250ms ease-in', keyframes([
            style({opacity: 1, transform: 'translateX(0)', offset: 0}),
            style({opacity: 0.5, transform: 'translateX(5%)', offset: 0.3}),
            style({opacity: 0, transform: 'translateX(-20%)', offset: 1.0})
          ]))
        ]), {optional: true})
      ])
    ])
  ]
})
export class CatalogGridComponent implements OnInit {
  @Input() productData;
  // data to get
  wpPosts             : any;
  wpPostsErr          : any;
  // search data variable
  keyword: any;



  constructor(
    private wp: WpApiService,
    private orderPipe: OrderPipe
  ) { }

  ngOnInit() {
      // Get Catalog
      this.wp.getPosts().subscribe(data => {
      this.wpPosts = this.orderPipe.transform(data, 'title.rendered');
    }, (err) => {
        this.wpPostsErr = err;
    });
  }

}
