// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit }    from '@angular/core';
import { WpApiService }         from './../../../Services/wp-api.service';

@Component({
  selector    : 'app-contact-us',
  templateUrl : './contact-us.component.html',
  styleUrls   : ['./contact-us.component.less']
})
export class ContactUsComponent implements OnInit {
  wpData              : any;
  wpDataErr           : any;
  constructor(private wp: WpApiService) { }
  ngOnInit() {
    this.wp.getSpecificPage('contact-us').subscribe(data => {
      this.wpData = data;
    }, (err) => {
      this.wpDataErr = err;
    });
  }
}
