import { Component, OnInit } from '@angular/core';
import { WpApiService } from './../../../Services/wp-api.service';

@Component({
  selector: 'app-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.less']
})
export class FrontPageComponent implements OnInit {

  frontPage: any;

  constructor(private wp: WpApiService) {}

  ngOnInit() {

    this.wp.frontPage.subscribe(data => {

      this.frontPage = data;

      console.log(this.frontPage);

    });

  }

}
