// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit }    from '@angular/core';
import { WpApiService }         from './../../../../Services/wp-api.service';
import { OrderPipe }            from 'ngx-order-pipe';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.less']
})
export class CatalogComponent implements OnInit {
  wpPosts             : any;
  wpPostsErr          : any;
  selectedProduct;
  selectProduct(id) {
    if (id === this.selectedProduct) {
      this.selectedProduct = null;
    } else {
      this.selectedProduct = id;
    }
  }
  constructor(
    private wp: WpApiService,
    private orderPipe: OrderPipe
  ) { }

  ngOnInit() {
     // Get Catalog
    this.wp.getPosts().subscribe(data => {
      this.wpPosts = this.orderPipe.transform(data, 'title.rendered');
    }, (err) => {
      this.wpPostsErr = err;
    });
  }
}
