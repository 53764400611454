import { WpApiService } from './../Services/wp-api.service';
import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less']
})
export class LayoutComponent implements OnInit {
  // loading: any;
  navigation: Array<{}> = [
    {slug: 'products', title: 'Products'},
    {slug: 'about-us', title: 'About Us', children: [
      {slug: 'about-us', title: 'Company Overview'},
      {slug: 'management', title: 'Meet our Management'}
    ]},
    {slug: 'contact-us', title: 'Contact Us'},
  ];

  secondaryNavigation: Array<{}> = [
    {slug: 'products', title: 'Products'},
    {slug: 'about-us', title: 'About Us'},
    {slug: 'contact-us', title: 'Contact Us'},
    {slug: 'privacy-policy', title: 'Privacy Policy'}
  ];

  hover = false;

  showNav(hasChildren) {
    if (hasChildren) {
      this.hover = true;
    }
  }

  hideNav() {
    this.hover = false;
  }

  constructor(@Inject(WINDOW) private window: Window, private wp: WpApiService) { }

  public mobileMenuOpen = false;
  public innerWidth: any;
  public slideInWidth = 400;
  @HostListener('window:resize', ['$event'])
  onresize(event) {
    this.innerWidth = this.window.innerWidth;
  }

  openMobileMenu() {
    if (this.mobileMenuOpen === false) {
      document.getElementById('body').style.overflow = 'hidden';
      this.mobileMenuOpen = true;
    } else {
      document.getElementById('body').style.overflow = 'auto';
      this.mobileMenuOpen = false;
    }
  }

  mobileContentStyles() {
    const styles = {'min-width': this.innerWidth + 'px'};
    return styles;
  }

  onActivate(event) {
    window.scroll(0, 0);
  }

  ngOnInit() {
    this.innerWidth = this.window.innerWidth;
  }
}
