import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-product-launch',
  templateUrl: './product-launch.component.html',
  styleUrls: ['./product-launch.component.less']
})
export class ProductLaunchComponent implements OnInit {

  @Input() content;

  constructor() { }

  ngOnInit() {

    console.log(this.content);

  }

}
