// tslint:disable: import-spacing tslint:disable: typedef-whitespace
import { Component, OnInit } from '@angular/core';
import { WpApiService }      from './../../../../Services/wp-api.service';

@Component({
  selector: 'app-meet-our-management',
  templateUrl: './meet-our-management.component.html',
  styleUrls: ['./meet-our-management.component.less']
})
export class MeetOurManagementComponent implements OnInit {
  wpData    : any;
  wpDataErr : any;
  wpLeaders : any;
  constructor(private wp: WpApiService) { }

  ngOnInit() {
    this.wp.getSpecificPage('meet-our-management').subscribe(data => {
      this.wpData = data;
    }, (err) => {
      this.wpDataErr = err;
    });
    this.wp.getLeaders().subscribe(data => {
      this.wpLeaders = data;
    }, (err) => {
      console.log(err);
    });
  }
}
