// tslint:disable: import-spacing
import { NgModule }               from '@angular/core';
import { BrowserModule }          from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule }           from '@angular/common';
import { HttpClientModule }       from '@angular/common/http';
import { RouterModule }           from '@angular/router';
import { AppRoutingModule }       from '../app-routing.module';
import { OrderModule }            from 'ngx-order-pipe';
import { FormsModule }            from '@angular/forms';
// Components
import { LayoutComponent }            from './layout.component';
import { FrontPageComponent }         from './Components/front-page/front-page.component';
import { AboutUsComponent }           from './Components/about-us/about-us.component';
import { ContactUsComponent }         from './Components/contact-us/contact-us.component';
import { ProductsComponent }          from './Components/products/products.component';
import { CatalogComponent }           from './Components/products/catalog/catalog.component';
import { SingleProductComponent }     from './Components/products/catalog/single-product/single-product.component';
import { LoaderComponent }            from './elements/loader/loader.component';
import { ContentLoaderComponent }     from './elements/content-loader/content-loader.component';
import { FeaturedProductsComponent }  from './Components/featured-products/featured-products.component';
import { LeaderBioComponent }         from './Components/about-us/leader-bio/leader-bio.component';
import { CatalogGridComponent }       from './Components/products/catalog-grid/catalog-grid.component';
import { SingleItemComponent }        from './Components/products/catalog-grid/single-item/single-item.component';
// Pipes
import { FilterPipe }                 from './Components/products/catalog-grid/SearchFilter/filter.pipe';
import { PrivacyComponent } from './Components/privacy/privacy.component';
import { MeetOurManagementComponent } from './Components/about-us/meet-our-management/meet-our-management.component';
import { ProductLaunchComponent } from './elements/product-launch/product-launch.component';
import { ProductLaunchSignupComponent } from './elements/product-launch/product-launch-signup/product-launch-signup.component';

@NgModule({
  declarations: [
    LayoutComponent,
    FrontPageComponent,
    AboutUsComponent,
    ContactUsComponent,
    ProductsComponent,
    CatalogComponent,
    SingleProductComponent,
    LoaderComponent,
    ContentLoaderComponent,
    FeaturedProductsComponent,
    LeaderBioComponent,
    CatalogGridComponent,
    SingleItemComponent,
    FilterPipe,
    PrivacyComponent,
    MeetOurManagementComponent,
    ProductLaunchComponent,
    ProductLaunchSignupComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    OrderModule,
    FormsModule
  ]
})
export class LayoutModule { }
