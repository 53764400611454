// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
// Services
import { WpApiService }      from './../../../../../Services/wp-api.service';

@Component({
  selector: 'app-single-item',
  templateUrl: './single-item.component.html',
  styleUrls: ['./single-item.component.less']
})
export class SingleItemComponent implements OnInit {

  constructor(
    private route : ActivatedRoute,
    private wp    : WpApiService
  ) { }

  wpProduct         : any;
  activeRoute       : any;
  productId         : any;
  // Url for selected image
  imgSrcUrl         : any;
  isactive          = false;
  disclaimerActive  = false;
  disclaimerUrl;

  ngOnInit() {
    this.activeRoute = this.route.params.subscribe(params => {
      if(typeof params['id'] !== 'undefined') {
        this.productId = params['id'];
        this.wp.getSpecificPost(this.productId + '?_embed=1').subscribe(data => {
          this.wpProduct = data;
        }, (err) => {
          console.log(err);
        });
      }
    });
  }

  activateLightbox(imgSrc) {
    this.imgSrcUrl = imgSrc;
    if (this.isactive === false) {
      this.isactive = true;
    } else {
      this.isactive = false;
    }
  }

  activateDisclaimer(url) {
    this.disclaimerUrl = url;
    let body = document.getElementsByTagName('body')[0];
    if (this.disclaimerActive === false) {
      this.disclaimerActive = true;
      body.style.overflowY = 'hidden';
    } else {
      this.disclaimerActive = false;
      body.style.overflowY = 'auto';
    }
  }
}
