import { Observable } from 'rxjs';
import { Component, OnInit, Input} from '@angular/core';
import { WpApiService } from './../../../Services/wp-api.service';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.less']
})
export class FeaturedProductsComponent implements OnInit {
  @Input() frontPage;
  featuredPosts: any;
  constructor(private wp: WpApiService) {
  }

  currentSlideIndex = 1;

  slide(nextPrev){
    if (nextPrev === 'next') {
      this.currentSlideIndex++;
    } else if (nextPrev === 'prev') {
      this.currentSlideIndex--;
    } else {
    }
  }

  ngOnInit() {
    this.wp.featuredPosts.subscribe(data => {
      this.featuredPosts = data;
      this.featuredPosts = this.featuredPosts.sort((val1, val2) => {

        return <any>new Date(val1.modified) - <any>new Date(val2.modified);

      })
      console.log(this.featuredPosts)
    });
  }

}
