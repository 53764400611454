// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
// Services
import { WpApiService }      from './../../../../Services/wp-api.service';

@Component({
  selector: 'app-leader-bio',
  templateUrl: './leader-bio.component.html',
  styleUrls: ['./leader-bio.component.less']
})
export class LeaderBioComponent implements OnInit {
  wpLeader    : any;
  activeRoute : any;
  leaderSlug  : any;
  constructor(
    private router : ActivatedRoute,
    private wp     : WpApiService
  ) { }

  ngOnInit() {
    this.activeRoute = this.router.params.subscribe(params => {
      if(typeof params['slug'] !== 'undefined') {
        this.leaderSlug = params['slug'];
        this.wp.getSpecificLeader(this.leaderSlug).subscribe(data => {
          this.wpLeader = data[0];
        }, (err) => {
          console.log(err);
        });
      }
    });
  }
}
