// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit }    from '@angular/core';
import { Observable }           from 'rxjs';
import { share }                from 'rxjs/operators';
import { WpApiService }         from './../../../Services/wp-api.service';

@Component({
  selector    : 'app-products',
  templateUrl : './products.component.html',
  styleUrls   : ['./products.component.less']
})
export class ProductsComponent implements OnInit {
  wpData              : any;
  wpDataErr           : any;
  ObserveSpecificPage : Observable<any>;

  constructor(private wp: WpApiService) { }

  ngOnInit() {
    // Get Products Page
    this.ObserveSpecificPage = this.wp.getSpecificPage('products').pipe(share());
    this.wp.getSpecificPage('products').subscribe(data => {
      this.wpData = data;
    }, (err) => {
      this.wpDataErr = err;
    });
  }
}
