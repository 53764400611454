import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WpApiService {
  frontPage: Observable<{}>;
  featuredPosts: Observable<{}>;
  pages: Observable<{}>;

  constructor(private http: HttpClient) {
    this.frontPage = this.getFrontPage().pipe(share());
    this.featuredPosts = this.getFeaturedPosts().pipe(share());
    this.pages = this.getPages().pipe(share());
  }

  // url = 'https://almaject.dev.premis.is/wp-json/wp/v2/';
  url = 'https://api.almaject.com/wp-json/wp/v2/';

  getFrontPage() {
    return this.http.get(this.url + 'pages/?slug=front-page', {});
  }

  getSpecificPage(pageslug) {
    return this.http.get(this.url + 'pages/?slug=' + pageslug);
  }

  getPages() {
    return this.http.get<any[]>(this.url + 'pages');
  }

  getPosts() {
    return this.http.get<any[]>(this.url + 'posts?per_page=100');
  }

  getSpecificPost(postID) {
    return this.http.get(this.url + 'posts/' + postID);
  }

  getFeaturedPosts() {
    return this.http.get(this.url + 'posts?categories=6');
  }

  getLeaders() {
    return this.http.get(this.url + 'leader_bio');
  }

  getSpecificLeader(leaderSlug) {
    return this.http.get(this.url + 'leader_bio?slug=' + leaderSlug);
  }
}
