import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// class
import { Signup } from './signup';

@Component({
  selector: 'app-product-launch-signup',
  templateUrl: './product-launch-signup.component.html',
  styleUrls: ['./product-launch-signup.component.less']
})
export class ProductLaunchSignupComponent implements OnInit {

  // contactFormUrl = 'http://almaject.dev.premis.is/wp-json/contact-form-7/v1/contact-forms/903/feedback';
  contactFormUrl = 'https://api.almaject.com/wp-json/contact-form-7/v1/contact-forms/976/feedback';

  model = new Signup('', '', '', '', '', '', '');

  submitted = false;
  valid = false;

  emailBlur = false;
  phoneBlur = false;

  formSuccess; 

  constructor(private http: HttpClient) { }

  ngOnInit() {}

  onSubmit(form) {

    form.form.updateValueAndValidity();

    if (form.valid) {

      this.newSignup(form);

    } else {

      Object.keys(form.form.controls).forEach(key => {

        form.form.get(key).markAsDirty();

      });

    }

  }

  newSignup(formArgs) {

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'multipart/form-data');
    headers.set('Accept', '*/*');

    let form = new FormData();
    form.append("your_name", this.model.your_name);
    form.append("your_email", this.model.your_email);
    form.append("message", this.model.message);
    form.append("requestType", this.model.requestType);
    form.append("phone", this.model.phone);
    form.append("organization", this.model.organization);
    form.append("cityState", this.model.cityState);

    this.http.post(this.contactFormUrl, form, {headers}).subscribe((data: any) => {

      if (data.status === 'mail_sent') {

        this.formSuccess = true;

        setTimeout(() => {

          formArgs.resetForm();
          form = undefined;
          this.formSuccess = false;

        }, 3000);

      }

    }, err => {

      console.log(err);

    });

  }

}
