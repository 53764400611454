export class Signup {

    constructor(

        public your_name: string,
        public your_email: string,
        public requestType: string,
        public phone: string,
        public organization: string,
        public cityState: string,
        public message?: string

    ) {}

}
