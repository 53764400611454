// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit }    from '@angular/core';
import { WpApiService }         from './../../../Services/wp-api.service';

@Component({
  selector    : 'app-about-us',
  templateUrl : './about-us.component.html',
  styleUrls   : ['./about-us.component.less']
})
export class AboutUsComponent implements OnInit {
  wpData    : any;
  wpDataErr : any;
  wpLeaders : any;
  constructor(private wp: WpApiService) { }

  ngOnInit() {
    this.wp.getSpecificPage('about-us').subscribe(data => {
      this.wpData = data;
    }, (err) => {
      this.wpDataErr = err;
    });
    this.wp.getLeaders().subscribe(data => {
      this.wpLeaders = data;
    }, (err) => {
      console.log(err);
    });
  }
}
