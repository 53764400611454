import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchProducts'
})

export class FilterPipe implements PipeTransform {
    transform(items: any[], keyword: string): any[] {
        if (!items) {
            return [];
        }
        if (!keyword) {
            return items;
        }
        keyword = keyword.toLowerCase();
        return items.filter( it => {
            return it.title.rendered.toLowerCase().replace(/,/g, '').includes(keyword);
        });
    }
}
