// tslint:disable: import-spacing
// tslint:disable: typedef-whitespace
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute }    from '@angular/router';
// Services
import { WpApiService }      from './../../../../../Services/wp-api.service';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.less']
})
export class SingleProductComponent implements OnInit {

  constructor(
    private route : ActivatedRoute,
    private wp    : WpApiService
  ) { }

  wpProduct     : any;
  activeRoute   : any;
  productId     : any;

  ngOnInit() {
    this.activeRoute = this.route.params.subscribe(params => {
      if(typeof params['id'] !== 'undefined') {
        this.productId = params['id'];
        this.wp.getSpecificPost(this.productId + '?_embed=1').subscribe(data => {
          this.wpProduct = data;
          console.log(this.wpProduct);

        }, (err) => {
          console.log(err);
        });
      }
    });
  }
}
